import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ListingsLayout from 'components/layouts/listings/listings.layout';
import { Container, Row, Col } from 'react-bootstrap';
import PressReleaseCard from 'components/press-release/card/press-release-card.component';
import Button from 'ui-kit/button/button';
import PressReleaseFeatured from 'components/press-release/featured/press-release-featured.component';

const PressReleases = ({ data }: { data: GatsbyTypes.PressReleasesDataQuery }) => {
    const { t } = useTranslation();
    const pageLimit = 12;
    const [itemsToShow, setItemsToShow] = useState(pageLimit);
    const featuredPressRelease =
        data.featuredPressRelease?.nodes?.length > 0 ? data.featuredPressRelease.nodes[0] : undefined;
    const pressReleases = data.allPressReleases?.nodes?.filter(
        (pressRelease: any) => pressRelease.drupal_internal__nid !== featuredPressRelease?.drupal_internal__nid
    );

    return (
        <ListingsLayout eyebrowText={t(`pages.newsInsights.eyebrowText`)} title={t(`pages.newsInsights.title`)}>
            <Container>
                {featuredPressRelease && (
                    <PressReleaseFeatured
                        eyebrowText={t('pages.newsInsights.featured')}
                        title={featuredPressRelease.title}
                        path={featuredPressRelease.path?.alias}
                        pathLabel={t('pages.newsInsights.readFullStory')}
                        publishedDate={featuredPressRelease.field_published_date}
                        author={featuredPressRelease.relationships?.field_author?.name}
                        tags={featuredPressRelease.relationships?.field_press_release_tags}
                        externalLink={featuredPressRelease.field_external_link?.uri}
                    />
                )}
                <Row xs={1} md={2} lg={3} xl={3}>
                    {pressReleases.slice(0, itemsToShow).map((pressRelease, index) => (
                        <Col className="my-4 flex-fill" key={index}>
                            <PressReleaseCard
                                title={pressRelease.title}
                                path={pressRelease.path?.alias}
                                publishedDate={pressRelease.field_published_date}
                                author={pressRelease.relationships?.field_author?.name}
                                tags={pressRelease.relationships?.field_press_release_tags}
                                externalLink={pressRelease.field_external_link?.uri}
                            />
                        </Col>
                    ))}
                </Row>
                {itemsToShow < pressReleases.length && (
                    <div className="text-center my-4">
                        <Button
                            label={t(`pages.newsInsights.loadMore`)}
                            className={'text-primary'}
                            type="button"
                            variant="text"
                            onClick={() => {
                                setItemsToShow(itemsToShow + pageLimit);
                            }}
                        />
                    </div>
                )}
            </Container>
        </ListingsLayout>
    );
};

export default PressReleases;

export const query = graphql`
    query PressReleasesData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        featuredPressRelease: allNodePressReleasePage(
            filter: { field_featured: { eq: true } }
            sort: { fields: field_published_date, order: DESC }
            limit: 1
        ) {
            nodes {
                title
                field_published_date(formatString: "MMMM DD, YYYY")
                field_external_link {
                    uri
                }
                relationships {
                    field_press_release_tags {
                        name
                    }
                    field_author {
                        name
                    }
                }
                path {
                    alias
                }
                drupal_internal__nid
            }
        }
        allPressReleases: allNodePressReleasePage(sort: { fields: field_published_date, order: DESC }) {
            nodes {
                title
                field_published_date(formatString: "MMMM DD, YYYY")
                field_external_link {
                    uri
                }
                drupal_internal__nid
                relationships {
                    field_press_release_tags {
                        name
                    }
                    field_author {
                        name
                    }
                }
                path {
                    alias
                }
            }
        }
    }
`;
